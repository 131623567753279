import React, { useState, useEffect } from "react";
import style from "./DashboardPartners.module.css";

import axios from "axios";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { db, storage } from '../../firebaseConfig'
import { addDoc, collection, serverTimestamp, onSnapshot } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { v4 } from 'uuid'
import { useNavigate } from 'react-router-dom';

const MySwal = withReactContent(Swal);

const initialState = {
  partner: ""
}

const DashboardPartners = () => {
  const [modal, setModal] = useState(false);
  const [partnerImage, setPartnerImage] = useState("")
  const [partners, setPartners] = useState([])
  const [partnerId, setPartnerId] = useState("")
  const [showDeleteModal, setshowDeleteModal] = useState(false)
  const [state, setState] = useState(initialState);
  const [files, setFiles] = useState(null)

  const navigate = useNavigate()

        const [showSuccessAlert, setShowSuccessAlert] = useState(false);
        const [showErrorAlert, setShowErrorAlert] = useState(false);
        const [showProcessAlert, setShowProcessAlert] = useState(false); 

    const { partner } = state;

    const handlePartner = (e) => {
      setState({ ...state, [e.target.name]: e.target.value });
    };

    const handleAddPartner = async (e) => {
      e.preventDefault();
    
      if (files ) {
        setShowProcessAlert(true);
    
        for (let i = 0; i < files.length; i++) {
          const storageRef = ref(storage, `partner/${files[i].name + v4()}`);
          const uploadTask = uploadBytesResumable(storageRef, files[i]);
    
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              switch (snapshot.state) {
                case 'paused':
                  break;
                case 'running':
                  break;
                default:
                  break;
              }
            },
            (error) => {
              console.log(error);
            },
            async () => {
              try {
                const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
                //  add url to firestore

                try {
                  await addDoc(collection(db, 'partners'), {
                    partner: downloadUrl,
                    timestamp: serverTimestamp(),
                  });
                  setShowProcessAlert(false);
                  setShowSuccessAlert(true);
    
                  navigate('/dashboard')
                } catch (error) {
                  console.error("Error adding Partners:", error);
                  setShowErrorAlert(true);
                }       
              } catch (error) {
                console.error("Error getting download URL:", error);
                setShowErrorAlert(true);
              }
            }
          );
        }
    
        setShowProcessAlert(false);
        setShowSuccessAlert(true);
        navigate('/dashboard');
      } else {
        console.error('No files selected for upload');
        setShowErrorAlert(true);
      }
    };

        const handleShowDeleteModal = (partnerId) => {
          setshowDeleteModal(!showDeleteModal)
          setPartnerId(partnerId)
        }

  const showModal = () => {
    setModal(!modal);
  };

  const handleDeletePartner = () => {
    axios.delete(`http://localhost:5000/api/deletePartner/${partnerId}`)
      .then(console.log("Success"))
      .catch((error) => console.error("Error fetching event:", error));
  }


        useEffect(() => {

          const unsub = onSnapshot(
            collection(db, 'partners'),
            (snapshot) => {
              let list = [];
              snapshot.docs.forEach((doc) => {
                list.push({ id: doc.id, ...doc.data() });
              });
              setPartners(list);
              console.log(partners)
            },
            (error) => {
              console.log(error);
            }
          );
      
          return () => {
            unsub();
          };
          // eslint-disable-next-line
        }, [])

        useEffect(() => {
          // Handle showing/hiding SweetAlerts here
          if (showProcessAlert) {
            MySwal.fire({
              title: <p>Adding Partner</p>,
              didOpen: () => {
                MySwal.showLoading();
              },
            });
          }

          if (showSuccessAlert) {
            MySwal.fire({
              title: "Added",
              text: "Partner Added Successfully",
              icon: "success",
              confirmButtonText: "Ok",
            });
          }

          if (showErrorAlert) {
            MySwal.fire({
              title: "Error",
              text: "Error Adding Partner",
              icon: "error",
              confirmButtonText: "Ok",
            });
          }
        }, [showProcessAlert, showSuccessAlert, showErrorAlert]);
  return (
    <div className={style.dashboardPartnerss}>
      <button className={style.showModalBtn} onClick={showModal}>
        +
      </button>
      {modal ? (
        <div className={style.modal}>
          <button className={style.closeModalBtn} onClick={showModal}>
            X
          </button>
          <h4 className={style.heading}>Add Partner</h4>
          <form
            onSubmit={handleAddPartner}
            encType="multipart/form-data"
          >
            <div className={style.inputs}>
              <label htmlFor="">Image</label>
              <input multiple onChange={(e) => {
                setFiles(e.target.files)
              }}  type="file" />
            </div>
            <button type="submit" >Add Partner</button>
          </form>
        </div>
      ) : (
        <div className={style.partnerContents}>
            <h4 className={style.heading}>All Partners</h4>
            <div className={style.allPartners}>
              {
                partners.map((partner) => {
                  return (
                    <div className={style.partnerImg} key={partner._id} >
                      <img src={partner.partner} alt="partnerImg" />
                      <div className={style.deleteImage}>
                          <button onClick={() => {handleShowDeleteModal(partner._id)}}  >Delete</button>
                        </div>
                    </div>
                  )
                })
              }
            </div>
        </div>
      )}

{
        showDeleteModal && <div className={style.deleteModal}>
          <div className={style.deleteModalContent}>
            <h2>Are you sure you want to delete the Partner?</h2>
            <div className={style.deletePartnerBtns}>
              <button onClick={handleShowDeleteModal} >Cancel</button>
              <button onClick={handleDeletePartner} className={style.deletePartner} >Delete</button>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default DashboardPartners;
