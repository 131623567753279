import React, { useRef, useState } from "react";
import Navbar from "../Navbar/Navbar";
import style from "./HomeHero.module.css";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";
import img1 from './../../assets/images/home-hero.jpg'

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

// import required modules
import { Autoplay, EffectFade } from "swiper/modules";

const HomeHero = () => {
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 1000, // Default duration for animations
      easing: "ease-in-out", // Smooth animation easing
      once: true, // Only animate once on scroll
      offset: 120, // Animation trigger offset
    });
  }, []);

  return (
    <div className={style.homeHero}>
      <Navbar />
      <div className={style.homeHeroWrapper}>
        <Swiper
          spaceBetween={10}
          // slidesPerView={1}
          effect={"fade"}
          centeredSlides={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          loop={true}
          modules={[EffectFade, Autoplay]}
          className={style.mySwiper}
        >
          <SwiperSlide>
            <div className={style.welcome}>
              <img src={img1} alt="first clide profile" />
              <p>welcome to</p>
              <h2>Global Youth Connect Foundation</h2>
              <p className={style.motto}>Join Us in empowering change</p>
              <div className={style.heroBtns}>
                <button onClick={() => navigate("/about-us")}>
                  Learn More
                </button>
                <button className={style.donate}>Donate</button>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={style.welcome}>
              <img src={img1} alt="youth empowerment" />
              <p>Building Tomorrow's Leaders</p>
              <h2>Empowering Youth Worldwide</h2>
              <p className={style.motto}>
                Creating opportunities for young voices to be heard
              </p>
              <div className={style.heroBtns}>
                <button onClick={() => navigate("/about-us")}>
                  Learn More
                </button>
                <button className={style.donate}>Donate</button>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={style.welcome}>
              <img src={img1} alt="community impact" />
              <p>Make an Impact</p>
              <h2>Transform Lives Together</h2>
              <p className={style.motto}>
                Every contribution creates lasting change
              </p>
              <div className={style.heroBtns}>
                <button onClick={() => navigate("/about-us")}>
                  Learn More
                </button>
                <button className={style.donate}>Donate</button>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>

        <div className={style.achievements}>
          <div
            data-aos="fade-up"
            data-aos-delay="100"
            className={style.achievement}
          >
            <h3>Mission</h3>
            <p>
              To be the national Network for all accredited foundations across
              the country and help people and organizations to invest in local
              communities where it is most needed and where it will make most
              impact.
            </p>
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="300"
            className={style.achievement}
          >
            <h3>Vision</h3>
            <p>
              Local philanthropy will be the norm and communities will be able
              to help all those in need.
            </p>
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="500"
            className={style.achievement}
          >
            <h3>Objectives</h3>
            <ul>
              <li>Grow a stronger and more cohesive network.</li>
              <li>Have influence in the places it matters.</li>
              <li>Be properly resourced to achieve our goals.</li>
            </ul>
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="700"
            className={style.achievement}
          >
            <h3>Values</h3>
            <ul>
              <li>Relief in communities and meeting local need.</li>
              <li>Passionate about philanthropy.</li>
              <li>Aspiring to excellence.</li>
              <li>Committed to learning.</li>
              <li>Adding value.</li>
              <li>Demonstrating leadership.</li>
              <li>Devoted to teamwork.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeHero;
