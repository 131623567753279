import React, { useState, useEffect } from 'react'
import style from './DashboardEvents.module.css'
import { FaPen, FaTrash } from 'react-icons/fa'

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import EditEvent from '../EditEvent/EditEvent';


import { db, storage } from '../../firebaseConfig'
import { addDoc, doc, getDoc, collection, serverTimestamp, onSnapshot, deleteDoc } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { v4 } from 'uuid'
import { useNavigate } from 'react-router-dom';

const MySwal = withReactContent(Swal);

const initialState = {
  projectTitle: "",
  projectAbout: "",
  projectDate: "",
  projectImage: "",
  projectLocation: "",
  projectType: ""

}

const DashboardEvents = () => {
  const [projectId, setProjectId] = useState("")
  const [state, setState] = useState(initialState);
  const [progress, setProgress] = useState(false)
  const [projects, setProjects] = useState([])
  const [modal, setModal] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [file, setFile] = useState(null)

  const navigate = useNavigate()

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showProcessAlert, setShowProcessAlert] = useState(false);
  const [showDeleteModal, setshowDeleteModal] = useState(false)

  const showModal = () => {
    setModal(!modal)
  }

  const handleShowDeleteModal = (projectId) => {
    setshowDeleteModal(!showDeleteModal)
    setProjectId(projectId)
  }


      
      const { projectTitle, projectAbout, projectLocation, projectDate, projectImage } = state;

      const handleProject = (e) => {
          setState({ ...state, [e.target.name]: e.target.value });
        };


      const handleAddProject = async (e) => { 
          e.preventDefault();
          setShowProcessAlert(true);

          console.log(state)
          if (projectTitle && projectDate && projectAbout && projectLocation && projectImage) {
    
                  try {
                    await addDoc(collection(db, 'projects'), {
                      ...state,
                      timestamp: serverTimestamp(),
                    });
                    setShowProcessAlert(false);
                    setShowSuccessAlert(true);

                    navigate('/dashboard')
                  } catch (error) {
                    console.error("Error adding Project:", error);
                    setShowErrorAlert(true);
                  } 
                
          }

        
      };

  useEffect(() => {
    const uploadFile = () => {
      const storageRef = ref(storage, `projectImages/${file.name + v4()}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          switch (snapshot.state) {
            case 'paused':
              break;
            case 'running':
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
            alert('Upload complete can now submit project');
            setState((prev) => ({ ...prev, projectImage: downloadUrl }));
          });
        }
      );
    };

    file && uploadFile()
}, [file]);

  // Get events

  // useEffect(() => {
  
  //     const getEvents = async () => {
  //       const eventRef = doc(db, 'events');
  //       const snapshot = await getDoc(eventRef);
  //       if (snapshot.exists()) {
  //         setEvents({...snapshot.data()});
  //         console.log(events)
  //       }
  //   }
  //   getEvents()
  // }, [])


  useEffect(() => {

    const unsub = onSnapshot(
      collection(db, 'projects'),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setProjects(list);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, [])
  


  // const handleDeleteEvent = () => {
  //   axios.delete(`http://localhost:5000/api/deleteEvent/${eventId}`)
  //     .then((response) => setEvents(response.data))
  //     .catch((error) => console.error("Error fetching event:", error));
  // }

  useEffect(() => {
    // Handle showing/hiding SweetAlerts here
    if (showProcessAlert) {
      MySwal.fire({
        title: <p>Adding Project</p>,
        didOpen: () => {
          MySwal.showLoading()
        },
      });
    }

    if (showSuccessAlert) {
      MySwal.fire({
        title: 'Added',
        text: 'Project Added Successfully',
        icon: 'success',
        confirmButtonText: 'Ok'
      });
    }

    if (showErrorAlert) {
      MySwal.fire({
        title: 'Error',
        text: 'Error Adding Project',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
  }, [showProcessAlert, showSuccessAlert, showErrorAlert]);

  const handleShowEdit = (projectId) => {
    setShowEdit(!showEdit)
    setProjectId(projectId)
  }
  return (
    <div className={style.dashboardEvents}>

      <button className={style.showModalBtn} onClick={showModal}>
        +
      </button>
      {modal ? (
        <div className={style.modal}>
          <button className={style.closeModalBtn} onClick={showModal}>
            X
          </button>
          <h4 className={style.heading}>Add A Project</h4>
          <form onSubmit={handleAddProject}
            method="post"
            encType="multipart/form-data">
            <div className={style.inputs}>
              <label htmlFor="">Title</label>
              <input name='projectTitle' onChange={
                handleProject
              } type="text" />
            </div>
            <div className={style.inputs}>
              <label htmlFor="">Location</label>
              <input name="projectLocation" onChange={handleProject} type="text" />
            </div>
            <div className={style.inputs}>
              <label htmlFor="">Project Type</label>
              <select name="projectType" placeholder="Select the project Type" onChange={handleProject}>
                <option value="" >Select Project Type</option>
                <option value="Educational Project" >Educational Project</option>
                <option value="Climate Change" >Climate Change</option>
                <option value="Health Project" >Health Project</option>
                <option value="Charity Project" >Charity Project</option>
                <option value="Millinium Development Goals" >Millinium Development Goals</option>
                <option value="Participatory Projects" >Participatory Projects</option>
              </select>
              {/* <input name="eventLocation" onChange={handleProject} type="text" /> */}
            </div>
            <div className={style.inputs}>
              <label htmlFor="">Date</label>
              <input name='projectDate' onChange={handleProject} type="date" />
            </div>
            <div className={style.inputs}>
              <label htmlFor="">Content</label>
              <textarea name="projectAbout" onChange={handleProject} id="" cols="30" rows="10"></textarea>
            </div>
            <div className={style.inputs}>
              <label htmlFor="">Image</label>
              <input name="projectImage" onChange={(e) => {setFile(e.target.files[0])}} type="file" />
            </div>
            <button type='submit' >Add Project</button>
          </form>
        </div>
      ) : (
        <div className={style.eventContents}>
          <h4 className={style.heading}>All Projects</h4>
          <table>
            <thead>
              <tr>
                <th>Title</th>
                <th>Location</th>
                <th>Date</th>
                <th>Content</th>
                <th>Imgage</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {projects.map((project) => {
                return (
                  <>
                    <tr key={project.id} >
                      <td> {project.projectTitle} </td>
                      <td> {project.projectLocation} </td>
                      <td> {project.projectDate} </td>
                      <td>
                        {project.projectAbout.length > 300
                          ? `${project.projectAbout.substring(0, 200)}...`
                          : project.projectAbout} </td>
                      <td> <img src={project.projectImage} alt="eventImg" /> </td>
                      <td> <button onClick={() => {handleShowEdit(project._id)}} > <FaPen /> </button> <button onClick={() => {handleShowDeleteModal(project.id)}} > <FaTrash /> </button>  </td>
                    </tr>
                  </>
                );
              })}


            </tbody>
          </table>
        </div>
      )}
      {
        showEdit && <EditEvent projectId={projectId} handleShowEdit={handleShowEdit} />
      }

      {
        showDeleteModal && <div className={style.deleteModal}>
          <div className={style.deleteModalContent}>
            <h2>Are you sure you want to delete the Project?</h2>
            <div className={style.deleteEventBtns}>
              <button onClick={handleShowDeleteModal} >Cancel</button>
              <button  className={style.deleteEvent} >Delete</button>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default DashboardEvents