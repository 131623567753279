import React from 'react'
import { FaEnvelope, FaFacebookSquare, FaInstagramSquare, FaLinkedinIn, FaMapMarkerAlt, FaPhoneAlt, FaTwitter } from 'react-icons/fa'
import style from './MainContact.module.css'

const MainContact = () => {
  return (
    <div className={style.mainContact}>
        <div className={style.mainContactWrapper}>
            <div className={style.mainContactlocation}>
                <div className={style.mainContactlocationCard}> <FaEnvelope className={style.mainContactlocationIcons} /> 
                <p>gyouthconnect@gmail.com</p> </div>
                <div className={style.mainContactlocationCard}> <FaPhoneAlt className={style.mainContactlocationIcons} /> <p>+233(0) 55 806 4605</p>  </div>
                <div className={style.mainContactlocationCard}> <FaMapMarkerAlt className={style.mainContactlocationIcons} /> <p>West Africa - Ghana (Kumasi)</p>  </div>
            </div>
            <div className={style.mainContactFormWrapper}>
                <div className={style.mainContactSocials}>
                    <div className={style.mainContactSocial}> <FaFacebookSquare className={style.mainContactSocialIcon} /> <p>Global Youth Connect Foundation</p>  </div>
                    <div className={style.mainContactSocial}> <FaTwitter className={style.mainContactSocialIcon} /> <p>Global Youth Connect Foundation</p>  </div>
                    <div className={style.mainContactSocial}> <FaInstagramSquare className={style.mainContactSocialIcon} /> Global Youth Connect Foundation </div>
                    <div className={style.mainContactSocial}> <FaLinkedinIn className={style.mainContactSocialIcon} /> <p>Global Youth Connect Foundation</p>  </div>
                </div>
                <div className={style.mainContactForm}>
                    <form action="">
                        <div className={style.email}>
                            <div className={style.inputs}>
                                <label htmlFor="name">Full Name</label>
                                <input placeholder='Full Name' type="text" />
                            </div>
                            <div className={style.inputs}>
                                <label htmlFor="name">Email</label>
                                <input placeholder='Email' type="email" />
                            </div>
                        </div>
                        <div className={style.inputs}>
                                <label htmlFor="name">Subject</label>
                                <input placeholder='Subject' type="text" />
                            </div>
                            <div className={style.inputs}>
                                <label htmlFor="name">Message</label>
                                <textarea placeholder='Message here...' name="" id="" cols="30" rows="10"></textarea>
                            </div>
                            <button>Send Message</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default MainContact