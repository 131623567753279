import React, { useEffect } from 'react'
import style from './ProjectSection.module.css'
import { useNavigate } from 'react-router-dom'
import focusProject from '../../data'
import AOS from 'aos'
import 'aos/dist/aos.css'

const ProjectSection = () => {
  const navigate = useNavigate()

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true, // Animation happens only once
    });
  }, []);

  return (
    <div className={style.projectSection}>
      <div className={style.projectSectionWrapper}>
        <div className={style.projectSectionHeading} data-aos="zoom-in">
          <h2>Focus Projects</h2>
          <p>
            Embark on an exciting journey with us as we strive to make a positive impact. Together, we can achieve remarkable outcomes and create lasting change.
          </p>
        </div>
        <div className={style.projects}>
          {focusProject.map((project, index) => (
            <div
              className={style.project}
              key={index}
              onClick={() => navigate(`/focus-project/${project.heading}`)}
              data-aos="flip-left"
              data-aos-delay={`${index * 100}`}
            >
              <div className={style.projectIcon}>
                <img src={project.img} alt="project-icon" />
              </div>
              <h4>{project.heading}</h4>
              <p>{project.brief}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ProjectSection