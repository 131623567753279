import React, { useState, useEffect } from 'react';
import style from './EditEvent.module.css'
import axios from 'axios'

function EditEvent({handleShowEdit, projectId}) {
  const [event, setEvent] = useState([])
  const [eventHeading, setEventTitle] = useState('');
  const [eventLoc, setEventLocation] = useState('');
  const [eventDat, setEventDate] = useState('');
  const [eventAb, setEventAbout] = useState('');
  const [eventImage, setEventImage] = useState('');

  useEffect(() => {

      axios.get(`http://localhost:5000/api/getEvent/${projectId}`)
        .then((response) => {
          setEvent(response.data)
          console.log(event)
          setEventTitle(event.eventTitle);
          setEventLocation(event.eventLocation);
          setEventDate(event.eventDate);
          setEventAbout(event.eventAbout);
        })
        .catch((error) => console.error("Error fetching event:", error));
  }, [projectId])

  console.log(eventHeading)
  

  const handleTitleChange = (e) => {
    setEventTitle(e.target.value);
  };

  const handleLocationChange = (e) => {
    setEventLocation(e.target.value);
  };

  const handleDateChange = (e) => {
    setEventDate(e.target.value);
  };

  const handleContentChange = (e) => {
    setEventAbout(e.target.value);
  };

  const handleImageChange = (e) => {
    setEventImage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // You can handle the submission here, for instance, send the updated event data to an API

    // For demonstration purposes, log the updated event data
    // console.log({
    //   title,
    //   location,
    //   date,
    //   content,
    //   image,
    // });
  };

  return (
    <div className={style.editEvent} >
      <div className={style.editEventContent}>
        <h2>Edit Event</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Title:</label>
          <input type="text" value={eventHeading} onChange={handleTitleChange} />
        </div>
        <div>
          <label>Location:</label>
          <input type="text" value={eventLoc} onChange={handleLocationChange} />
        </div>
        <div>
          <label>Date:</label>
          <input type="date" value={eventDat} onChange={handleDateChange} />
        </div>
        <div>
          <label>Content:</label>
          <textarea value={eventAb} onChange={handleContentChange} />
        </div>
        <div>
          <label>Image URL:</label>
          <input type="text"  onChange={handleImageChange} />
        </div>
        <button type="submit">Update Event</button>
      </form>
      </div>
    </div>
  );
}

export default EditEvent;
