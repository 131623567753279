import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import GalleryPage from "./pages/GalleryPage";
import GallerySingle from "./pages/GallerySingle";
import EventPage from "./pages/EventPage";
import ContactPage from "./pages/ContactPage";
import FocusProjectPage from "./pages/FocusProjectPage";
import Dashboard from "./pages/Dashboard";
import EventSinglePage from './pages/EventSinglePage'

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={ <HomePage /> } />
        <Route exact path="/about-us" element={ <AboutPage /> } />
        <Route exact path="/gallery" element={ <GalleryPage /> } />
      <Route path="/gallery/:galleryId/:galleryHeading" element={ <GallerySingle /> } />
      <Route path="/projects" element={ <EventPage /> } />
      <Route path="/project/:projectId" element={ <EventSinglePage /> } />
      <Route path="/contact-us" element={ <ContactPage /> } />
      <Route path="/focus-project/:focusId" element={ <FocusProjectPage /> } />
      <Route path="gycf/dashboard" element={ <Dashboard />} />
      </Routes>
    </Router>
  );
}

export default App;
