import React from 'react'
import style from './OtherPagesHero.module.css'
import Navbar from '../Navbar/Navbar'

const OtherPagesHero = ({heading}) => {
  return (
    <div className={style.otherPagesHero}>
      <Navbar />
        <div className={style.otherPagesHeroWrapper}>
            <h4 className={style.otherPagesHeroHead}> {heading} </h4>
        </div>
    </div>
  )
}

export default OtherPagesHero