import React from 'react'
import style from './Mission.module.css'

const Mission = () => {
  return (
    <div className={style.mission}>
        <div className={style.missionWrapper}>
            <div className={style.missioncard}>
                <h4>Mission</h4>
                <p>To be the national Network for all accredited foundations across the country and help people and organizations to invest in local communities where it is most needed and where it will make most impact.</p>
            </div>
            <div className={style.missioncard}>
                <h4>Vision</h4>
                <p>Local philanthropy will be the norm and communities will be able to help all those in need.</p>
            </div>
            <div className={style.missioncard}>
                <h4>Objectives</h4>
                <ul>
                      <li>Grow a stronger and more cohesive network.</li>
                      <li>Have influence in the places it matters.</li>
                      <li>Be properly resourced to achieve our goals.</li>
                </ul>
            </div>
            <div className={style.missioncard}>
                <h4>Values</h4>
                <ul>
                        <li>Relief in communities and meeting local need.</li>
                        <li>	Passionate about philanthropy.</li>
                        <li>	Aspiring to excellence.</li>
                        <li>	Committed to learning.</li>
                        <li>	Adding value </li>
                        <li>	Demonstrating leadership</li>
                        <li>	Devoted to teamwork.</li>
                      </ul>
            </div>
        </div>
    </div>
  )
}

export default Mission