import React from 'react'
import HomeHero from '../components/HomeHero/HomeHero'
// import Mission from '../components/Mission/Mission'
import AboutSection from '../components/AboutSection/AboutSection'
import ProjectSection from '../components/ProjectSection/ProjectSection'
import EventSection from '../components/EventSection/EventSection'
import VolunteersSection from '../components/VolunteersSection/VolunteersSection'
import Footer from './../components/Footer/Footer'
import ContactCard from './../components/ContactCard/ContactCard'
import Partners from './../components/Partners/Partners'

const HomePage = () => {
  return (
    <>
    <HomeHero />
    {/* <Mission /> */}
    <AboutSection />
    <ProjectSection />
    <EventSection />
    <VolunteersSection />
    <ContactCard />
    <Partners />
    <Footer />
    </>
  )
}

export default HomePage
