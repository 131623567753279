import React from 'react'
import Navbar from './../components/Navbar/Navbar'
import OtherPagesHero from './../components/OtherPagesHero/OtherPagesHero'
import EventSingle from '../components/EventSingle/EventSingle'
import Footer from '../components/Footer/Footer'

const EventSinglePage = () => {
  return (
    <>
    <OtherPagesHero heading="Project" />
    <EventSingle />
    <Footer />
    </>
  )
}

export default EventSinglePage