import React from 'react'
import style from './SideNav.module.css'
import { useNavigate } from 'react-router-dom';

const SideNav = ({ handleActiveBtn }) => {
  const navigate = useNavigate()

  const onNavClick = (item) => {
    handleActiveBtn(item)
  }
  return (
    <div className={style.sideNav}>
      <div className={style.sideNavWrapper}>
      <button
        className={style.dashboardMainBtn}
        onClick={() => {
          onNavClick("0");
        }}
      >
        Dashboard
      </button>

      <div className={style.otherDashboardBtn}>
        <button className={style.dashboardBtn} onClick={() => onNavClick("1")}>
          Projects
        </button>
        <button className={style.dashboardBtn} onClick={() => onNavClick("4")}>
          Gellery
        </button>
        {/* <button className={style.dashboardBtn} onClick={() => onNavClick("5")}>
          Testimonials
        </button> */}
        <button className={style.dashboardBtn} onClick={() => onNavClick("6")}>
          Volunteers
        </button>
        <button className={style.dashboardBtn} onClick={() => onNavClick("7")}>
          Partners
        </button>
        <button className={style.dashboardBtn} onClick={() => onNavClick("8")}>
          Email
        </button>
        {/* <button className={style.dashboardBtn} onClick={() => onNavClick("9")}>
          Admins
        </button> */}
        <button className={style.dashboardBtnLogOut} onClick={() => {
                            localStorage.removeItem('isLoggedIn');
                            localStorage.removeItem('username');
                            localStorage.clear();
                            navigate('/');
                            window.location.reload();
                          }}>
          Log Out
        </button>
      </div>
      </div>
    </div>
  );
};

export default SideNav