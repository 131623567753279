import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
const firebaseConfig = {
  apiKey: "AIzaSyDyIlKFUpFYXgN68KjkkPm4K-731nNQmgc",
  authDomain: "gycf-5cf0f.firebaseapp.com",
  projectId: "gycf-5cf0f",
  storageBucket: "gycf-5cf0f.appspot.com",
  messagingSenderId: "584329879154",
  appId: "1:584329879154:web:709ba3e8302eb4aaa3aceb"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };