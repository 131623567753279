import React, { useState, useEffect } from 'react'
import style from './GallerySingleComponent.module.css'
import { FaTimes } from 'react-icons/fa'
import axios from 'axios'
import { db, storage } from '../../firebaseConfig'
import { addDoc, collection, serverTimestamp, onSnapshot, query, limit } from 'firebase/firestore'



const GallerySingleComponent = () => {
  const [modal, setModal] = useState(false)
  const [tempImgSrc, setTempImgSrc] = useState('')
  const [galleries, setGalleries] = useState([])


  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc)
    setModal(true)
  }

  const closeModal = () => {
    setModal(false)
  }

  useEffect(() => {

    const unsub = onSnapshot(
      collection(db, 'galleries'),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setGalleries(list);
        console.log(galleries)
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, [])
  return (
    <>
      <div className={modal ? style.open : style.modal}>
        <FaTimes onClick={closeModal} className={style.close} />
        <img src={tempImgSrc} alt="" />
      </div>
      <div className={style.mainGalleryWrapper}>
        {
          galleries.map((gallery) => {
            return (
              <div
                key={gallery.id}
                className={style.pic}
              >
                {
                  gallery.images.map((img) => {
                    return (
                      <div className={style.mainImg} onClick={() => {
                        getImg(img);
                      }} >
                        <img
                          src={img}
                          alt="galleryImg"
                        />
                      </div>
                      
                    )
                  })
                }
                        
              </div>
            );
          })
        }

      </div>
    </>
  )
}

export default GallerySingleComponent