import React, { useState, useEffect } from "react";
import style from "./DashboardEmail.module.css";
import { db, storage } from '../../firebaseConfig'
import { addDoc, collection, serverTimestamp, onSnapshot, query, limit } from 'firebase/firestore'


import axios from "axios";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const DashboardEmail = () => {
  const [modal, setModal] = useState(false);
    const [volunteerName, setVolunteerName] = useState("");
    const [recievers, setRecievers] = useState([])
  const [volunteers, setVolunteers] = useState([]);



  useEffect(() => {

    const unsub = onSnapshot(
      collection(db, 'volunteers'),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setVolunteers(list);
        console.log(volunteers)
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, [])
    
    const getRecievers = (reciever) => {
        setRecievers([...recievers, reciever])
        console.log(recievers)
    }

  return (
    <div className={style.dashboardEmails}>
        <div className={style.emailContents}>
          <h4 className={style.heading}>All Volunteer</h4>
          <table>
            <thead>
              <tr>
                <th>Add</th>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
              </tr>
            </thead>
            <tbody>
              {volunteers.map((volunteer) => {
                return (
                  <>
                        <tr key={volunteer._id}>
                      <td> <input type="checkbox" onChange={() => {getRecievers(volunteer.volunteerEmail)}} name="" id="" /> </td>
                      <td> {volunteer.volunteerName} </td>
                      <td> {volunteer.volunteerEmail} </td>
                      <td> {volunteer.volunteerPosition} </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
              </table>
              
              <div className={style.sendMail}>
                  <div className={style.sendMailForm}>
                      <form action="">
                          <div className={style.inputs}>
                              <label htmlFor="subject"> Subject</label>
                              <input type="text" name="subject" />
                          </div>
                          <div className={style.inputs}>
                              <label htmlFor="message"> Message</label>
                              <textarea name="message" id="" cols="30" rows="10"></textarea>
                          </div>
                          <button>Send Message</button>
                      </form>
                  </div>
              </div>
        </div>
    </div>
  );
};

export default DashboardEmail;
