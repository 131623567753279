import React, { useState, useEffect } from "react";
import style from "./DashboardTestimonials.module.css";
import axios from "axios";
import { FaTrash } from 'react-icons/fa'

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { db, storage } from '../../firebaseConfig'
import { addDoc, collection, serverTimestamp, onSnapshot } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { v4 } from 'uuid'
import { useNavigate } from 'react-router-dom';

const MySwal = withReactContent(Swal);

const initialState = {
  testimonyName: "",
  testimony: "",
  testimonyImage: ""
}

const DashboardTestimonials = () => {
  const [modal, setModal] = useState(false);
  // const [testimonyName, setTestimonyName] = useState("")
  // const [testimony, setTestimony] = useState("")
  // const [testimonyImage, setTestimonyImage] = useState("")
  const [testimonies, setTestimonies] = useState([])
  const [testimonyId, setTestimonyId] = useState('')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [state, setState] = useState(initialState);
  const [file, setFile] = useState(null)

  const navigate = useNavigate()

      const [showSuccessAlert, setShowSuccessAlert] = useState(false);
      const [showErrorAlert, setShowErrorAlert] = useState(false);
      const [showProcessAlert, setShowProcessAlert] = useState(false); 

      const handleShowDeleteModal = (testimonyId) => {
        setShowDeleteModal(!showDeleteModal)
        setTestimonyId(testimonyId)
      }

      const { testimonyName, testimony, testimonyImage } = state;

      const handleTestimony = (e) => {
          setState({ ...state, [e.target.name]: e.target.value });
        };


        const handleAddTestimony = async (e) => { 
          e.preventDefault();
          setShowProcessAlert(true);
      
          console.log(state)
          if (testimonyName && testimony && testimonyImage) {
      
                  try {
                    await addDoc(collection(db, 'testimonies'), {
                      ...state,
                      timestamp: serverTimestamp(),
                    });
                    setShowProcessAlert(false);
                    setShowSuccessAlert(true);
      
                    navigate('/dashboard')
                  } catch (error) {
                    console.error("Error adding Testimony:", error);
                    setShowErrorAlert(true);
                  }      
          }
      };

      useEffect(() => {
        const uploadFile = () => {
          const storageRef = ref(storage, `testimonyImages/${file.name + v4()}`);
          const uploadTask = uploadBytesResumable(storageRef, file);
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              switch (snapshot.state) {
                case 'paused':
                  break;
                case 'running':
                  break;
                default:
                  break;
              }
            },
            (error) => {
              console.log(error);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
                alert('Upload complete can now submit event');
                setState((prev) => ({ ...prev, testimonyImage: downloadUrl }));
              });
            }
          );
        };
    
        file && uploadFile()
    }, [file]);

    useEffect(() => {

      const unsub = onSnapshot(
        collection(db, 'testimonies'),
        (snapshot) => {
          let list = [];
          snapshot.docs.forEach((doc) => {
            list.push({ id: doc.id, ...doc.data() });
          });
          setTestimonies(list);
          
        },
        (error) => {
          console.log(error);
        }
      );
  
      return () => {
        unsub();
      };
      // eslint-disable-next-line
    }, [])

      const handleDeleteTestimony = () => {
        axios.delete(`http://localhost:5000/api/deleteTestimony/${testimonyId}`)
          .then((response) => setTestimonies(response.data))
          .catch((error) => console.error("Error fetching event:", error));
      }

  const showModal = () => {
    setModal(!modal);
  };


      useEffect(() => {
      // Fetch properties from your server when the component mounts
      axios.get("http://localhost:5000/api/getTestimonies")
        .then((response) => setTestimonies(response.data))
        .catch((error) => console.error("Error fetching testimonies:", error));
    }, []);

      useEffect(() => {
        // Handle showing/hiding SweetAlerts here
        if (showProcessAlert) {
          MySwal.fire({
            title: <p>Adding Event</p>,
            didOpen: () => {
              MySwal.showLoading();
            },
          });
        }

        if (showSuccessAlert) {
          MySwal.fire({
            title: "Added",
            text: "Event Added Successfully",
            icon: "success",
            confirmButtonText: "Ok",
          });
        }

        if (showErrorAlert) {
          MySwal.fire({
            title: "Error",
            text: "Error Adding Event",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      }, [showProcessAlert, showSuccessAlert, showErrorAlert]);
  return (
    <div className={style.dashboardTestimoials}>
      <button className={style.showModalBtn} onClick={showModal}>
        +
      </button>
      {modal ? (
        <div className={style.modal}>
          <button className={style.closeModalBtn} onClick={showModal}>
            X
          </button>
          <h4 className={style.heading}>Add Testimony</h4>
          <form
            onSubmit={handleAddTestimony}
            method="post"
            encType="multipart/form-data"
          >
            <div className={style.inputs}>
              <label htmlFor="">Name</label>
              <input
                name="testimonyName"
                onChange={handleTestimony}
                type="text"
              />
            </div>
            <div className={style.inputs}>
              <label htmlFor="">Content</label>
              <textarea
                onChange={handleTestimony}
                name="testimony"
                id=""
                cols="30"
                rows="10"
              ></textarea>
            </div>
            <div className={style.inputs}>
              <label htmlFor="">Image</label>
              <input
                onChange={(e) => {setFile(e.target.files[0])}}
                type="file"
              />
            </div>
            <button>Add Testimony</button>
          </form>
        </div>
      ) : (
        <div className={style.eventContents}>
          <h4 className={style.heading}>All Testimonial</h4>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Content</th>
                <th>Img</th>
                <th>Edit</th>
              </tr>
            </thead>
              <tbody>
                {
                  testimonies.map((testimony) => {
                    return (
                      <tr>
                      <td> {testimony.testimonyName} </td>
                      <td> {testimony.testimony} </td>
                      <td>
                        {" "}
                        <img src={`http://localhost:5000${testimony.testimonyImage}`} alt="" />{" "}
                      </td>
                      <td> <button onClick={() => {handleShowDeleteModal(testimony._id)}} > <FaTrash /> </button> </td>
                    </tr>
                    )
                  })
                }  
            </tbody>
          </table>
        </div>
      )}

{
        showDeleteModal && <div className={style.deleteModal}>
          <div className={style.deleteModalContent}>
            <h2>Are you sure you want to delete the testimony?</h2>
            <div className={style.deleteTestimonyBtns}>
              <button onClick={handleShowDeleteModal} >Cancel</button>
              <button onClick={handleDeleteTestimony} className={style.deleteTestimony} >Delete</button>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default DashboardTestimonials;
